<template>
  <div>
    <Badge variant="success" label="Active" v-if="formattedStatus === 'active'" />
    <Badge variant="tertiary" label="Inactive" v-if="formattedStatus === 'inactive'" />
    <Badge variant="default" label="Pending" v-if="formattedStatus === 'pending'" />
    <Badge variant="default" label="Void" v-if="formattedStatus === 'void'" />
    <Badge variant="danger" label="Failed" v-if="formattedStatus === 'failed'" />
  </div>
</template>
<script>
import Badge from '@/components/rbComponents/Badge';
export default {
  components: {
    Badge
  },
  props: {
    status: {
      type: String,
      required: true
    }
  },
  computed: {
    formattedStatus() {
      return this.status.toLowerCase();
    }
  }
};
</script>
