<template>
  <div>
    <h6 class="font-semibold mb-2" v-if="!readOnlyMode">Cart</h6>
    <div class="table-overflow mb-0">
      <table class="table-fixed min-w-full divide-y divide-gray-200">
        <caption class="sr-only">
          Cart
        </caption>
        <!-- Ticket Packages Section -->
        <thead class="bg-gray-100">
          <tr>
            <th scope="col" class="whitespace-nowrap px-3 py-3 text-xs tracking-wide text-gray-900 w-8/12">Raffle</th>
            <th scope="col" class="whitespace-nowrap px-3 py-3 text-xs tracking-wide text-gray-900 w-28">
              Ticket Packages
            </th>
            <th scope="col" class="whitespace-nowrap px-3 py-3 text-xs tracking-wide text-gray-900 text-right">
              Total
            </th>
            <th scope="col" class="w-8 bg-gray-100"></th>
          </tr>
        </thead>
        <tbody v-if="items.length === 0">
          <tr class="justify-content-center">
            <td colspan="9" class="p-4">
              <div class="flex items-center justify-center m-auto">
                <p class="py-3">Your cart is currently empty, please add an order item above</p>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody class="divide-y divide-gray-200" v-else>
          <tr v-for="item in items" :key="item.event.name" class="h-1" :class="{ 'bg-red-200': item.voided }">
            <td class="px-3 py-3 text-xs tracking-wide text-gray-900 h-1">
              <div class="flex h-full">
                <p>{{ itemName(item) }}</p>
              </div>
            </td>
            <td class="px-3 py-3 text-xs tracking-wide text-gray-900">
              <ul>
                <li
                  v-for="ticketPackage in formatTicketPackages(item.ticketPackages)"
                  :key="ticketPackage"
                  class="mb-1"
                >
                  {{ ticketPackage }}
                </li>
              </ul>
            </td>
            <td v-if="item.voided" class="px-3 py-3 text-xs tracking-wide text-gray-900 text-right w-28">
              ({{ formatCurrency(getTotal(item)) }})
            </td>
            <td v-else class="px-3 py-3 text-xs tracking-wide text-gray-900 text-right w-28">
              {{ formatCurrency(getTotal(item)) }}
            </td>
            <td class="px-3 py-3 text-xs tracking-wide text-gray-900 text-right" v-if="!disabled">
              <span class="cursor-pointer" @click="deleteItem(item)">
                <font-awesome-icon class="text-md" :icon="['far', 'trash-can']" />
              </span>
            </td>
          </tr>
        </tbody>

        <!-- Donations Section -->
        <thead class="bg-gray-100" v-if="items.donationEnabled">
          <tr>
            <th scope="col" class="whitespace-nowrap px-3 py-3 text-xs tracking-wide text-gray-900 max-w-24">
              Donation
            </th>
            <th scope="col" class="whitespace-nowrap px-3 py-3 text-xs tracking-wide text-gray-900 max-w-24">Amount</th>
            <th scope="col" class="whitespace-nowrap px-3 py-3 text-xs tracking-wide text-gray-900 max-w-24 text-right">
              Total
            </th>
            <th scope="col" class="w-8 bg-gray-100"></th>
          </tr>
        </thead>
        <tbody v-if="items.donationEnabled">
          <tr :class="{ 'bg-red-200': donationRefunded }">
            <td class="px-3 py-3 text-xs tracking-wide text-gray-900">Charitable Donation</td>
            <td v-if="!readOnlyMode" class="px-3 py-3 text-xs tracking-wide text-gray-900">
              <b-form-group
                label-for="input-donation-amount"
                invalid-feedback="Invalid Input"
                class="max-w-24 w-full p-0 mb-0"
              >
                <b-form-input
                  id="input-donation-amount"
                  name="input-donation-amount"
                  v-model="donation"
                  v-validate="'numeric|min:0'"
                  :state="validateState('input-donation-amount')"
                  :data-vv-as="'input-donation-amount'"
                  type="number"
                  :disabled="disabled"
                  default-value="0"
                ></b-form-input>
              </b-form-group>
            </td>
            <td v-else class="px-3 py-3 text-xs tracking-wide text-gray-900 text-left">
              {{ formatCurrency(donation ? donation : 0) }}
            </td>
            <td v-if="donationRefunded" class="px-3 py-3 text-xs tracking-wide text-gray-900 text-right">
              ({{ formatCurrency(donation ? donation : 0) }})
            </td>
            <td v-else class="px-3 py-3 text-xs tracking-wide text-gray-900 text-right">
              {{ formatCurrency(donation ? donation : 0) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex justify-end items-center" :class="!disabled ? 'mr-12' : 'mr-4'">
      <div v-if="!readOnlyMode && isHomeLottery" class="flex items-center gap-2 mt-3">
        <div class="border border-gray-200 rounded-lg py-2 px-4 mr-6">
          <b-form-checkbox
            id="input-member-opt-in-enabled"
            v-model="memberToggle"
            name="input-member-opt-in-checkbox"
            class="flex-1"
          >
            Make this order a membership
          </b-form-checkbox>
        </div>
      </div>
      <p class="mt-2 font-bold">Total: {{ formatCurrency(cartTotal) }}</p>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@rafflebox-technologies-inc/rafflebox-lib';

export default {
  props: {
    cartItems: {
      type: Array,
      required: true
    },
    donationAmount: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnlyMode: {
      type: Boolean,
      default: false
    },
    donationRefunded: {
      type: Boolean,
      default: false
    },
    memberOptIn: {
      type: Boolean,
      default: false
    },
    isHomeLottery: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: [],
      memberToggle: this.memberOptIn,
      donation: 0
    };
  },
  watch: {
    cartItems: {
      handler() {
        this.items = this.cartItems;
      },
      deep: true
    },
    donation: {
      handler() {
        this.$emit('updateDonation', Number(this.donation));
      }
    },
    donationAmount: {
      handler() {
        this.donation = this.donationAmount;
      }
    },
    memberOptIn: {
      handler() {
        if (this.memberOptIn !== this.memberToggleDetails) {
          this.memberToggle = this.memberOptIn;
        }
      }
    },
    memberToggle: {
      handler() {
        this.$emit('updateMemberOptIn', this.memberToggle);
      }
    }
  },
  computed: {
    cartTotal() {
      const ticketValue = this.items.reduce((acc, item) => {
        const itemTotal = this.getTotal(item);
        if (item.voided) {
          return acc;
        }
        return acc + itemTotal;
      }, 0);

      if (this.donationRefunded) {
        return ticketValue;
      } else {
        return ticketValue + Number(this.donation);
      }
    }
  },
  mounted() {
    this.items = this.cartItems;
    this.donation = this.donationAmount;
    this.memberToggle = this.memberOptIn;
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    formatTicketPackages(ticketPackages) {
      const purchased = ticketPackages.filter((ticketPackage) => ticketPackage.quantity > 0);
      return purchased.map(
        (ticketPackage) =>
          `${ticketPackage.quantity} x ${ticketPackage.numTickets} for ${formatCurrency(ticketPackage.price, {
            precision: 0
          })}`
      );
    },
    getTotal(item) {
      return item.ticketPackages.reduce((acc, ticketPackage) => acc + ticketPackage.price * ticketPackage.quantity, 0);
    },
    deleteItem(row) {
      this.$emit('deleteItem', row);
    },
    itemName(item) {
      if (item.orderId) {
        return `#${this.formatUuid(item.orderId)} - ${item.event.name}`;
      } else {
        return item.event.name;
      }
    }
  }
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
